import React, { useEffect, useState } from 'react';
import {
  solicitarCartaoSwap,
  solicitarCartaoUsuario,
  toggleModalSolicitarCartaoNominal,
} from '../../../store/user/actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Alert } from 'reactstrap';
import ModalMensagem from '../../../views/Transaction/ModalMensagem';
import AnimatedLoading from '../../Loader/AnimatedLoading';
import api from '../../../service/api';
import Loading from '../../Loader/Loading';

export const ModalSolicitarCartaoNominal = (props) => {
  const { isOpen, toggle } = props;

  const { user } = useSelector((state) => state.user);
  const [mensagem, setMensagem] = useState('');
  const [modalMensagem, setModalMensagem] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [vinculoCartao, setVinculoCartao] = useState(0);

  useEffect(() => {
    setVinculoCartao(0);
  }, [isOpen]);

  const openModalMessage = (res) => {
    const { mensagem, retorno } = res;

    retorno && retorno == 'sucesso' ? setSucesso(true) : setSucesso(false);
    setLoaded(false);
    setMensagem(mensagem);
    setModalMensagem(true);
  };

  function click() {
    setLoading(true);
    const params = {
      UUID: user.UUID,
      TIPO_CONTA: vinculoCartao,
      ID_CONTA_BACEN: user.CONTA_BACEN[0] ? user.CONTA_BACEN[0].ID : null,
    };
    api
      .post(`cartao/criar/cartao`, params)
      .then((res) => {
        openModalMessage(res.data);
      })
      .catch((error) => openModalMessage(error.response.data))
      .finally(() => setLoading(() => false));
  }

  function close() {
    toggle();
  }

  return (
    <Modal isOpen={isOpen} toggle={() => close()}>
      <ModalHeader
        toggle={() => {
          close();
        }}
      >
        Solicitar Cartão Nominal
      </ModalHeader>
      {!loading ? (
        <>
          <FormGroup>
            <ModalBody>
              <span>Selecione o vínculo do cartão</span>

              <Input
                onChange={(e) => setVinculoCartao(e.target.value)}
                style={{ marginBottom: '1rem' }}
                type="select"
                size="sm"
              >
                <option value={0}>Selecione</option>
                <option value={1}>Conta bancária</option>
                <option value={2}>Conta cartão</option>
              </Input>
            </ModalBody>
          </FormGroup>
          <AnimatedLoading isActive={loaded} />

          <ModalFooter>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Button
                style={{ marginTop: '8px' }}
                color="secondary"
                size="sm"
                onClick={() => {
                  close();
                }}
                block
              >
                Cancelar
              </Button>
              <Button
                color="success"
                size="sm"
                onClick={() => {
                  click();
                }}
                disabled={vinculoCartao == 0}
                block
              >
                Confirmar
              </Button>
            </div>
          </ModalFooter>
        </>
      ) : (
        <Loading color="blue" />
      )}
      <ModalMensagem
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        toggle={() => setModalMensagem(false)}
      />
    </Modal>
  );
};
