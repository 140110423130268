import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { modelo1201XML } from '../../../store/accounting/action';
import { Alert, Box, MenuItem, Snackbar, TextField, Button, Typography } from '@mui/material';
import Loading from '../../Loader/Loading';
import api from '../../../service/api';

export default function Modelo1201() {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(null);
  const [xmlRequest, setXmlRequest] = useState({
    data: '',
    tipo_envio: 'I',
  });

  const [fileData, setFileData] = useState({
    content: '',
    data: '',
  });

  const handleDispatch = async () => {
    if (!xmlRequest.data) {
      setError('A data é obrigatória.');
      return;
    }

    const [year, month] = xmlRequest.data.split('-');
    const currentDate = new Date();
    const selectedYear = parseInt(year);
    const selectedMonth = parseInt(month);

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    if (selectedYear > currentYear || (selectedYear === currentYear && selectedMonth >= currentMonth)) {
      setError('A data não pode ser do mês atual ou futuro.');
      return;
    }

    setLoading(true);
    setFileData({ content: '', data: '' });
    const { data, tipo_envio } = xmlRequest;

    try {
      const { xmlContentBase64, dataBase } = await dispatch(modelo1201XML(data, tipo_envio));

      setFileData({ content: xmlContentBase64, data: dataBase });
      setLoading(false);
    } catch (error) {
      setError('Erro ao gerar o XML. Tente novamente.');
      setLoading(false);
    }
  };

  const generate1201File = async () => {
    try {
      const response = await api.post('/pix/arquivo/1201', {
        DATA_REFERENCIA: fileData.data,
        ARQUIVO_BASE64: fileData.content,
      });

      handleDownload(
        new TextDecoder('utf-8').decode(Uint8Array.from(atob(fileData.content), (c) => c.charCodeAt(0))),
        response.data.nome_arquivo,
      );
    } catch (error) {
      setError('Erro ao fazer a requisição do arquivo 1201');
    }
  };

  const handleDownload = (content, fileName) => {
    if (content && fileName) {
      const blob = new Blob([content], { type: 'text/xml' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Box sx={{ overflowY: 'scroll', maxHeight: '100%' }}>
      <Box mr="1rem" id="container" sx={{ padding: '1rem' }}>
        <Snackbar
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          open={Boolean(error)}
          autoHideDuration={6000}
          onClose={() => setError('')}
        >
          <Alert onClose={() => setError('')} severity="error" sx={{ marginBottom: '10px' }}>
            {error}
          </Alert>
        </Snackbar>
        <Box mb={2} mt={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TextField
              type="month"
              size="small"
              InputProps={{
                inputProps: {
                  max: (() => {
                    const currentDate = new Date();
                    currentDate.setMonth(currentDate.getMonth() - 1);
                    const year = currentDate.getFullYear();
                    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
                    return `${year}-${month}`;
                  })(),
                },
              }}
              value={xmlRequest.data}
              onChange={(e) => setXmlRequest({ ...xmlRequest, data: e.target.value })}
              required
              sx={{ marginRight: '10px' }}
            />

            <TextField
              size="small"
              select
              labelId="select_tipo_envio"
              id="tipo_envio"
              value={xmlRequest.tipo_envio}
              label="Tipo envio"
              onChange={(e) => setXmlRequest({ ...xmlRequest, tipo_envio: e.target.value })}
            >
              <MenuItem value="I">Novas Informações</MenuItem>
              <MenuItem value="S">Alteração de informações</MenuItem>
            </TextField>

            <Button onClick={handleDispatch} color="primary" variant="contained" sx={{ marginLeft: '16px' }}>
              BUSCAR
            </Button>
          </Box>

          {fileData.content && (
            <Box>
              <Button onClick={generate1201File} variant="contained" color="primary">
                GERAR XML
              </Button>
            </Box>
          )}
        </Box>

        {loading ? (
          <Loading color="#11cdef" />
        ) : fileData.content ? (
          <Box mt={3} sx={{ userSelect: 'none', WebkitUserSelect: 'none', msUserSelect: 'none' }}>
            <h3>Prévia do Arquivo XML:</h3>
            <pre>
              {new TextDecoder('utf-8').decode(Uint8Array.from(atob(fileData.content), (c) => c.charCodeAt(0)))}
            </pre>
          </Box>
        ) : (
          loading === null && (
            <Typography
              variant="h5"
              sx={{
                width: '100%',
                fontWeight: '600',
                fontSize: '18px',
                flexShrink: 0,
                marginTop: '20px',
                marginX: 'auto',
                textAlign: 'center',
              }}
            >
              Realize uma nova busca para visualizar
            </Typography>
          )
        )}
      </Box>
    </Box>
  );
}