import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button, ModalFooter } from 'reactstrap';
import { useSelector } from 'react-redux';
import { formatMoney } from '../../utils/formatMoneyBRL';
import formatCPF from '../../utils/formatCPF';
import formatCNPJ from '../../utils/formatCNPJ';
import {
  getTedHistory,
  tedAproved,
  tedNegaded,
  msBaasTed,
  getWithdrawalTransactionsID,
} from '../../store/transactions/actions';
import StatusContainerTed from '../../components/StatusContainer/StatusContainerTed';
import { useDispatch } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '../../components/Table/Table.jsx';
import AnimatedLoading from '../../components/Loader/AnimatedLoading';
import ReplyIcon from '@mui/icons-material/Reply';
import GenericModal from '../../components/Modais/GenericModal';
import ModalInfoReversalTransfer from './component/ModalInfoReversalTransfer';
import isCpf from '../../utils/isCpf';
import ModalMensagemSemReloadNew from '../Transaction/ModalMensagemSemReloadNew.jsx';
import ModalConfirm from '../User/components/modals/components/ModalConfirm/ModalConfirm.jsx';
import ModalReproveTed from './ModalReproveTed/ModalReproveTed.jsx';
import Loading from '../../components/Loader/Loading.jsx';

const ModalInfoTransfer = ({ modal, id_ted, toggle, callback, title, filtrar }) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [modalMensagem, setModalMensagem] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [tipo, setTipo] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalAprove, setModalAprove] = useState(false);
  const [modalReprove, setModalReprove] = useState(false);
  const [modalInfoReversalTransfer, setModalInfoReversalTransfer] = useState(false);
  const [saleInfos, setSaleInfos] = useState({});

  const [modalBaas, setModalBaas] = useState({
    open: false,
    header: '',
    body: '',
    footer: '',
  });

  async function handleOpenModal() {
    setLoading(true);
    await dispatch(
      getWithdrawalTransactionsID(id_ted, (res) => {
        if (res) {
          setSaleInfos({ ...res });
        }
      }),
    );
    await dispatch(getTedHistory(id_ted));
    setExpanded(false);
    setLoading(false);
  }

  useEffect(() => {
    if (modal) {
      handleOpenModal();
    }
  }, [id_ted, dispatch]);

  const { historyTed } = useSelector((state) => state.transactions);
  const codEstorno = historyTed.length > 0 && historyTed[0].COD_ESTORNO;

  async function aproved() {
    await dispatch(
      tedAproved(
        {
          ID: id_ted,
          CPFCNPJ_USUARIO: saleInfos.CPFCNPJ,
          CPFCNPJ_COBAN: localStorage.getItem('CPFCNPJ'),
          VALOR: saleInfos.VALOR,
        },
        (res) => {
          setLoaded(false);
          openModalMessage(res.data, 'Aprovado');
        },
      ),
    );
  }

  const openModalMessage = (res, tipo) => {
    const { mensagem, retorno } = res;

    retorno && retorno == 'sucesso' ? setSucesso(true) : setSucesso(false);

    setMensagem(mensagem);
    setTipo(tipo);
    setModalMensagem(true);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function handleModalBaas() {
    setModalBaas({
      open: true,
      header: <h1>Disparo de TED</h1>,
      body: <span>Deseja enviar para o microserviço terceiros?</span>,
      footer: (
        <ModalFooter>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              style={{ marginTop: '8px' }}
              color="secondary"
              size="sm"
              onClick={() => setModalBaas({ open: false, header: '', body: '', footer: '' })}
              block
            >
              Cancelar
            </Button>
            <Button color="success" size="sm" onClick={() => dispatch(msBaasTed(id_ted, callbackMsBassTed))} block>
              Confirmar
            </Button>
          </div>
        </ModalFooter>
      ),
    });
  }

  function callbackMsBassTed(callback) {
    setModalBaas({ open: false, header: '', body: '', footer: '' });
    setModalBaas({
      open: true,
      header: <h1>{callback.retorno}</h1>,
      body: <span>{callback.mensagem}</span>,
      footer: (
        <ModalFooter>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              style={{ marginTop: '8px' }}
              color={callback.retorno == 'erro' ? 'danger' : 'success'}
              size="sm"
              onClick={() => setModalBaas({ open: false, header: '', body: '', footer: '' })}
              block
            >
              Ok
            </Button>
          </div>
        </ModalFooter>
      ),
    });
  }

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={() => {
          setSaleInfos({});
          toggle();
        }}
      >
        {loading ? (
          <Loading color="blue" />
        ) : (
          <>
            <ModalHeader
              toggle={() => {
                setSaleInfos({});
                toggle();
              }}
            >
              <h3>
                {title}
                {+saleInfos.ID_TIPO_USUARIO !== 1 && (
                  <ReplyIcon style={{ cursor: 'pointer' }} onClick={handleModalBaas} />
                )}
              </h3>
            </ModalHeader>
            <ModalBody
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                wordBreak: 'break-word',
              }}
            >
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>Código da Transação</h5>
                  <p>{saleInfos.ID}</p>
                </Col>
              </Row>
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>Tipo Transação</h5>
                  <p>{saleInfos.DESCRICAO ? saleInfos.DESCRICAO : '-----'}</p>
                </Col>
              </Row>
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>Data / Hora</h5>
                  <p>
                    {saleInfos.DATA} {saleInfos.HORA}
                  </p>
                </Col>
              </Row>
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>Status Transação</h5>
                  <StatusContainerTed code={saleInfos.ID_STATUS} description={saleInfos.STATUS} />
                </Col>
              </Row>
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>Valor</h5>
                  <p>R$ {formatMoney(saleInfos.VALOR)}</p>
                </Col>
              </Row>
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>Valor Total</h5>
                  <p>R$ {formatMoney(saleInfos.VALOR_TOTAL)}</p>
                </Col>
              </Row>
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>Taxa</h5>
                  <p>R$ {formatMoney(saleInfos.VALOR_SPREAD)} </p>
                </Col>
              </Row>
              <Row style={{ width: '100%' }}>
                <Col>
                  <h4>Origem</h4>
                </Col>
              </Row>
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>Nome</h5>
                  <p>{saleInfos.NOME}</p>
                </Col>
              </Row>
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>CPF/CNPJ</h5>
                  <p>
                    {saleInfos.CPFCNPJ && isCpf(saleInfos.CPFCNPJ)
                      ? formatCPF(saleInfos.CPFCNPJ)
                      : formatCNPJ(saleInfos.CPFCNPJ)}
                  </p>
                </Col>
              </Row>
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>Banco</h5>
                  <p>{saleInfos.NOME_BANCO_ORIGEM}</p>
                </Col>
              </Row>
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>Agência</h5>
                  <p>{saleInfos.AGENCIA_ORIGEM}</p>
                </Col>
              </Row>
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>Conta</h5>
                  <p>
                    {saleInfos.CONTA_ORIGEM}-{saleInfos.DIGITO_ORIGEM}
                  </p>
                </Col>
              </Row>
              <Row style={{ width: '100%' }}>
                <Col>
                  <h4>Destino</h4>
                </Col>
              </Row>
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>Nome</h5>
                  <p>{saleInfos.NOME_TRANSFERENCIA}</p>
                </Col>
              </Row>
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>CPF/CNPJ</h5>
                  <p>
                    {saleInfos.CPFCNPJ_TRANSFERENCIA && saleInfos.CPFCNPJ_TRANSFERENCIA.length === 11
                      ? formatCPF(saleInfos.CPFCNPJ_TRANSFERENCIA)
                      : formatCNPJ(saleInfos.CPFCNPJ_TRANSFERENCIA)}
                  </p>
                </Col>
              </Row>
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>Banco</h5>
                  <p>{saleInfos.NOME_BANCO_DESTINO}</p>
                </Col>
              </Row>
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>Agência</h5>
                  <p>{saleInfos.AGENCIA_DESTINO}</p>
                </Col>
              </Row>
              <Row style={{ width: '49%' }}>
                <Col>
                  <h5>Conta</h5>
                  <p>
                    {saleInfos.CONTA_DESTINO}-{saleInfos.DIGITO_DESTINO}
                  </p>
                </Col>
              </Row>
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
                sx={{
                  width: '100%',
                  boxShadow: '0',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    bgcolor: 'rgb(236, 239, 240)',
                    borderRadius: '5px',
                    paddingTop: '0.4rem',
                  }}
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    <h5>Historico TED</h5>
                  </Typography>
                  <Typography>
                    {expanded === false && (
                      <h6>
                        {historyTed.length > 0 &&
                          historyTed[historyTed.length - 1].STATUS_FINAL +
                            ' | ' +
                            historyTed[historyTed.length - 1].DATA}
                      </h6>
                    )}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Table
                      headers={[
                        {
                          info: 'Status',
                          style: { textAlign: 'center' },
                        },
                        {
                          info: 'Descrição',
                          style: { textAlign: 'center' },
                        },
                        {
                          info: 'Data',
                          style: { textAlign: 'center' },
                        },
                      ]}
                      data={historyTed.map((info, index) => {
                        return {
                          data: [
                            {
                              info: info.STATUS_FINAL,
                              style: { textAlign: 'center' },
                            },
                            {
                              info: info.RETORNO,
                              style: { textAlign: 'center' },
                            },
                            {
                              info: info.DATA,
                              style: { textAlign: 'center' },
                            },
                          ],
                        };
                      })}
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </ModalBody>
            {saleInfos.ID_STATUS === '1' && localStorage.getItem('nivelAcesso') != '6' && (
              <ModalFooter style={{ display: 'flex', alignItems: 'baseline' }}>
                <Button
                  color="danger"
                  size="sm"
                  block
                  onClick={() => {
                    setModalReprove(true);
                  }}
                >
                  Reprovar
                </Button>
                <Button
                  color="success"
                  size="sm"
                  block
                  onClick={() => {
                    setModalAprove(true);
                  }}
                >
                  Aprovar
                </Button>
              </ModalFooter>
            )}
            {saleInfos.ID_STATUS === '6' && localStorage.getItem('nivelAcesso') != '6' && (
              <ModalFooter style={{ display: 'flex', alignItems: 'baseline' }}>
                <Button color="danger" size="sm" block onClick={() => setModalInfoReversalTransfer(true)}>
                  Reprovar
                </Button>
              </ModalFooter>
            )}
          </>
        )}
      </Modal>
      <ModalMensagemSemReloadNew
        open={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        tipo={tipo}
        toggle={() => setModalMensagem(false)}
        callback={() => {
          setModalMensagem(false);
          toggle();
          filtrar();
        }}
      />
      <AnimatedLoading isActive={loaded} />

      <GenericModal
        open={modalBaas.open}
        close={() => setModalBaas({ open: false, header: '', body: '', footer: '' })}
        header={modalBaas.header}
        body={modalBaas.body}
        footer={modalBaas.footer}
      />

      <ModalConfirm
        open={modalAprove}
        callback={() => {
          setModalAprove(false);
          aproved();
          setLoaded(true);
        }}
        texto={'Aprovar transação?'}
        toggle={() => setModalAprove(false)}
      />

      <ModalReproveTed
        open={modalReprove}
        callback={() => {
          setModalReprove(false);
          callback();
        }}
        id_ted={id_ted}
        toggle={() => setModalReprove(false)}
      />

      <ModalInfoReversalTransfer
        open={modalInfoReversalTransfer}
        toggle={() => setModalInfoReversalTransfer(false)}
        callback={() => {
          setModalInfoReversalTransfer(false);
          callback();
        }}
        id_usuario={id_ted}
        cod_estorno={codEstorno}
      />
    </>
  );
};

export default ModalInfoTransfer;
