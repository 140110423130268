import React, { useState } from 'react';
import { finishUser } from '../../../store/user/actions';
import { connect, useDispatch } from 'react-redux';
import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Alert } from 'reactstrap';
import ModalMensagem from '../../../views/Transaction/ModalMensagem';
import { Box, MenuItem, TextField } from '@mui/material';

const ModalJustificativa = (props) => {
  const { isOpen, modalTransition, backdropTransition, toggle } = props;
  const dispatch = useDispatch;
  const [mensagem, setMensagem] = useState('');
  const [modalMensagem, setModalMensagem] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [visible, setVisible] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const [conta, setConta] = useState('');

  const onDismiss = () => setVisible(!visible);

  const user = props.user.user || props.userData;
  const userLogin = localStorage.getItem('CPFCNPJ');

  const params = {
    CPFCNPJ_USUARIO: userLogin,
    CONTA: conta,
    MOTIVO: mensagem,
  };
  const handle = (target) => {
    setMensagem(target);
  };

  const openModalMessage = (res) => {
    const { mensagem, retorno } = res;

    retorno && retorno == 'sucesso' ? setSucesso(true) : setSucesso(false);

    setMensagem(mensagem);
    setModalMensagem(true);
  };

  const click = () => {
    if (mensagem == '') {
      setVisible(!visible);
      setMensagemAlerta('Preencha os campos');
    } else if (mensagem != '' && user.CONTA_BACEN[0].UUID) {
      if (!params.CONTA) params.CONTA = user.CONTA_BACEN[0].UUID;
      props.finishUser(params, (res) => {
        openModalMessage(res);
      });
    }
  };

  const alert = () => {
    return (
      <Alert color="danger" isOpen={visible} toggle={() => onDismiss()}>
        {mensagemAlerta}
      </Alert>
    );
  };

  return (
    <Modal isOpen={isOpen} modalTransition={modalTransition} backdropTransition={backdropTransition} toggle={toggle}>
      <ModalHeader toggle={() => toggle()}>Encerramento Conta</ModalHeader>
      <FormGroup>
        <ModalBody>
          <Box>
            {user.CONTA_BACEN && user.CONTA_BACEN.length > 0 && (
              <Box>
                <p>Conta a encerrar</p>
                <TextField
                  select
                  required
                  label="Conta"
                  name="CONTA"
                  defaultValue={user.CONTA_BACEN[0].UUID}
                  onChange={({ target: { value } }) => setConta(value)}
                >
                  {user.CONTA_BACEN.map((conta) => (
                    <MenuItem key={conta.UUID} value={conta.UUID}>
                      {conta.CONTA}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )}
          </Box>
          <Box>
            <span>Motivo do encerramento da conta</span>
            <Input placeholder="Descreva..." size="sm" onChange={(e) => handle(e.target.value)}></Input>
            {alert()}
          </Box>
        </ModalBody>
      </FormGroup>
      <ModalFooter>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Button style={{ marginTop: '8px' }} color="secondary" size="sm" onClick={() => toggle()} block>
            Cancelar
          </Button>
          <Button color="success" size="sm" onClick={() => click()} block>
            Confirmar
          </Button>
        </div>
      </ModalFooter>

      <ModalMensagem
        modal={modalMensagem}
        mensagem={mensagem}
        sucesso={sucesso}
        toggle={() => setModalMensagem(false)}
      />
    </Modal>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, {
  finishUser,
})(ModalJustificativa);
