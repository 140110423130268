import React, { useEffect, useState } from "react";
import CustomDataTable from "../../../components/DataTable/DataTable";
import { formatCNPJ, formatCPF, formatDateBR, formatMoneyBRL, isCpf } from "../../../utils";
import StatusContainer from "../../../components/StatusContainer/StatusContainer";
import { useSelector } from "react-redux";
import Loading from "../../../components/Loader/Loading";

export default function Pending({ search, getValues, handleClick }) {
    const {
        pagamentos, pagination,
        loading_transaction
    } = useSelector((state) => state.transactions);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (pagination && pagination.page && pagamentos) {
            setLoading(false);
        }
    }, [pagination]);

    return (
        <>
            {loading ? (
                <Loading color="blue" />
            ) : (
                <CustomDataTable
                    loading={loading_transaction}
                    pagination={pagination}
                    action={(row) => handleClick(row.UUID)}
                    previousPage={{ onClick: () => search({ ...getValues(), PAGE: pagination.prevPage }) }}
                    nowPage={{ page: pagination.page, onClick: () => search({ ...getValues(), PAGE: pagination.page }) }}
                    nextPage={{ onClick: () => search({ ...getValues(), PAGE: pagination.nextPage }) }}
                    data={pagamentos ? pagamentos : []}
                    headers={[
                        {
                            name: 'DATA/HORA',
                            selector: (row) => formatDateBR(row.CREATED_AT),
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'CPF/CNPJ',
                            selector: (row) =>
                                row.CPFCNPJ_PAGADOR && (isCpf(row.CPFCNPJ_PAGADOR) ? formatCPF(row.CPFCNPJ_PAGADOR) : formatCNPJ(row.CPFCNPJ_PAGADOR)),
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'NOME',
                            selector: (row) => row.NOME_PAGADOR,
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'CONTA',
                            selector: (row) => row.CONTA_PAGADOR,
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'FORMA PAGAMENTO',
                            selector: (row) => row.TIPO_PAGAMENTO_DESCRICAO,
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'RECEBEDOR',
                            selector: (row) => row.NOME_RECEBEDOR,
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'PARCEIRO',
                            selector: (row) => (
                                <StatusContainer code={row.TIPO_USUARIO} description={row.TIPO_USUARIO_DESCRICAO} />
                            ),
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'VALOR PAGO',
                            selector: (row) => (+row.VALOR_TOTAL).toLocaleString("pt-BR", {style:"currency", currency:"BRL"}),
                            style: { justifyContent: 'start' },
                            sortable: true,
                        },
                        {
                            name: 'STATUS',
                            selector: (row) => (
                                <StatusContainer code={row.STATUS_TRANSACAO} description={row.STATUS_TRANSACAO_DESCRICAO} />
                            ),
                            style: { justifyContent: 'start' },
                            sortable: true,
                        }
                    ]}
                    customStyles={{
                        cells: {
                            style: {
                                cursor: 'pointer',
                            },
                            headCells: {
                                style: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textAlign: 'center'
                                },
                            },
                        },
                    }}
                />
            )}
        </>
    );
}