import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, Button } from 'reactstrap';
import api from '../../../service/api';
import ModalMensagemSemReload from '../../Transaction/ModalMensagemSemReload';
import { listaUserDetalhesWithoutLoading } from '../../../store/user/actions';

const ButtonGroupConta = (props) => {
  const dispatch = useDispatch();

  const {user: { user }} = useSelector((state) => state);
  const [modalMessage, setModalMessage] = useState({ open: false, message: '', success: false });
  const [allAccountsClosed, setAllAccountsClosed] = useState(true);

  function handleClick(operation) {
    props.setModalSenhaUnica(true);
    props.setOperation(operation);
  }

  async function handleMigrateAccount() {
    api
      .post("/conta-digital/migrar/terceiro", { ID_CLIENTE: user.ID })
      .then((response) => {
        dispatch(listaUserDetalhesWithoutLoading(user.UUID));
        setModalMessage({
          open: true,
          message: response.data.mensagem,
          success: true,
        });
      });
  }

  useEffect(() => {
    if (props.user.CONTA_BACEN && props.user.CONTA_BACEN.length > 0) {
      if (props.user.CONTA_BACEN.find((item) => item.DT_ENCERRAMENTO === null)) {
        setAllAccountsClosed(false);
      }
    }
  }, [props.user.CONTA_BACEN]);

  return (
    <>
      <ButtonGroup size="sm" style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
        {/* <Button
          color="primary"
          style={{ borderRadius: '5px' }}
          onClick={() => {
            handleClick('maisContaBancaria');
          }}
        >
          + Conta Bancária
        </Button> */}
        {/* 
        {props.user.STATUS_CONTA_DIGITAL === '4' ? (
          <Button
            color="primary"
            onMouseEnter={(e) => handleHoverButton(e.target.id, true)}
            onMouseLeave={(e) => handleHoverButton(e.target.id, false)}
            style={{ borderRadius: '5px', transform: hoverButton[2] ? 'translateY(-.1rem)' : '' }}
            id="2"
            onClick={() => {
              props.setState({
                ...props.user,
                modalPasswordContaDigital: true,
                dados: {
                  CPFCNPJ_USUARIO: props.user.CPFCNPJ,
                },
              });
            }}
          >
            Solicitar Cartão Hub Fintech
          </Button>
        ) : null} */}
        {user.CONTA_NO_TERCEIRO != "S" && user.CONTA && (
          <>
            <Button
              color="primary"
              style={{ borderRadius: '5px' }}
              onClick={() => {
                handleMigrateAccount();
              }}
            >
              Migrar Conta Para a Sinqia
            </Button>
          </>
        )}
        {/* {+localStorage.getItem('nivelAcesso') === 1 && +props.user.STATUS === 7 && (
          <>
            {props.user.CONTA_BACEN && props.user.CONTA_BACEN.length > 0 && (
              <Button
                color="primary"
                style={{ borderRadius: '5px' }}
                onClick={() => {
                  handleClick('bloquearSaldoCliente');
                }}
              >
                Bloquear Saldo Cliente
              </Button>
            )}
          </>
        )} */}
        {+localStorage.getItem('nivelAcesso') === 1 && (
          <>
            {props.user.CONTA_BACEN && props.user.CONTA_BACEN.length > 0 && (
              <Button
                color="primary"
                style={{ borderRadius: '5px' }}
                onClick={() => {
                  handleClick('operarSaldoCliente');
                }}
              >
                Operar Saldo Cliente
              </Button>
            )}
          </>
        )}
        {props.user.CONTA_BACEN && props.user.CONTA_BACEN.length > 0 && !allAccountsClosed ? (
          <Button
            color="primary"
            style={{ borderRadius: '5px' }}
            onClick={() => {
              handleClick('encerrarConta');
            }}
          >
            Encerrar Conta
          </Button>
        ) : (
          <Button color="primary" style={{ borderRadius: '5px' }} onClick={() => handleClick('criarContaDigital')}>
            Criar Conta Digital
          </Button>
        )}
      </ButtonGroup>
      <ModalMensagemSemReload
        modal={modalMessage.open}
        toggle={() => setModalMessage({ open: false, message: '', success: false })}
        mensagem={modalMessage.message}
        sucesso={modalMessage.success}
      />
    </>
  );
};

export default ButtonGroupConta;
