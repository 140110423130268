import {
  TRANSACTION_RESPONSE,
  TRANSACTION_RESPONSE_TED,
  TRANSACTION_AVULSE,
  TRANSACTION_REMESSA,
  GET_ANTICIPATION,
  GET_ANTICIPATION_SUCCESS,
  GET_ANTICIPATION_FAIL,
  LISTANF_RESPONSE,
  DOWNLOADNF_RESPONSE,
  CARTAO_CONCILIACAO,
  LANCAMENTO,
  APORTE_RESPONSE,
} from './types';
import api from '../../service/api';
import React from 'react';
import FixBrokenScreen from '../../utils/fixBrokenScreen';
import { removeSpecialCharacters } from '../../utils/functions';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';

export const getCardInnTransactions = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      params.search = removeSpecialCharacters(params.search);
      const { status, data } = await api.post('/cip/listar/transacoes', params);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: {
          listCardSalesInn: data || [],
          DADOS_VENDAS_CARTAO: data.DADOS_VENDAS_CARTAO,
          loading_transaction: false,
          pagination: data.PAGINATION || {},
        },
      });
      if (callback) callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });
      if (callback) callback({});
    }
  };
};

export const getCardInnTransactionsID = (ID, callback = () => null) => {
  const IDsearch = { id: ID };
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
    });
    try {
      const { status, data } = await api.post('/cip/listar/transacoes', IDsearch);
      if (data.retorno === 'erro') {
      } else
        dispatch({
          type: TRANSACTION_RESPONSE,
          payload: {
            dadosCardID: data.RETORNO,
            loading_transaction: false,
          },
        });

      if (callback) callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });
      if (callback) callback({});
    }
  };
};

export const getCardTransactions = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      params.SEARCH = removeSpecialCharacters(params.SEARCH);
      const { status, data } = await api.post('cartao/transacao/listar', params);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: {
          listCardSales: data || [],
          DADOS_VENDAS_CARTAO: data.DADOS_VENDAS_CARTAO,
          loading_transaction: false,
          pagination: data.PAGINATION || {},
        },
      });
      if (callback) callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });
      if (callback) callback({});
    }
  };
};

export const getCardTransactionsID = (ID, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.get(`admin/cartao/transacao/detalhes?ID=${ID}`);
      if (data.retorno === 'erro') {
      } else
        dispatch({
          type: TRANSACTION_RESPONSE,
          payload: {
            dadosCardID: data.data,
            loading_transaction: false,
          },
        });

      if (callback) callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });
      if (callback) callback({});
    }
  };
};

export const getBilletTransactions = (parameter, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      parameter.search = removeSpecialCharacters(parameter.search);
      const { status, data } = await api.post('boleto/adm/listar', parameter);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: {
          listBilletSales: data || [],
          DADOS_VENDAS_BOLETO: data.DADOS_VENDAS_BOLETO,
          loading_transaction: false,
          pagination: data.PAGINATION,
        },
      });
      if (callback) callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      if (callback) callback({});
    }
  };
};

export const getBilletTransactionsID = (ID, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: false },
    });
    try {
      const { status, data } = await api.get(`boleto/detalhes/boleto/${ID}`);

      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { detailBilletSales: data.data, loading_transaction: false },
      });
      if (data.retorno == 'erro') alert(data.mensagem);
      if (callback) callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });
      if (callback) callback({});
    }
  };
};

//ANTECIPAÇÕES

export const getAnticipation = (type, callback) => {
  return async (dispatch) => {
    dispatch({ type: GET_ANTICIPATION, anticipationType: type });

    if (!type) type = 'pendente';
    var aux = 1;
    if (type == 'pendente') aux = 1;
    if (type == 'aprovado') aux = 2;
    if (type == 'negado') aux = 3;

    api
      .get(`transacao/listar/antecipacao?STATUS=${aux}`)
      .then((res) => {
        dispatch({
          type: GET_ANTICIPATION_SUCCESS,
          payload: res.data,
          anticipationType: type,
        });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ANTICIPATION_FAIL,
          payload: err,
          anticipationType: type,
        });
        if (callback) callback(false, err);
      });
  };
};

export const getAnticipationID = (ID, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.get('transacao/antecipados?IDANTECIPA=' + ID);
      if (Object.keys(data).length > 0)
        dispatch({
          type: TRANSACTION_RESPONSE,
          payload: {
            listSales: data.VENDAS || [],
            DADOS_VENDA: data.DADOS_VENDA,
            loading_transaction: false,
          },
        });

      if (callback) callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });
      if (callback) callback({});
    }
  };
};

// SAQUES
export const getWithdrawalTransactions = (parameter, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.post('transacao/listar/saque', parameter);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: {
          listWithdrawals: data.RETORNO || [],
          DADOS_SAQUES: data.DADOS_SAQUES,
          pagination: data.PAGINATION,
          loading_transaction: false,
        },
      });
      if (callback) callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      if (callback) callback({});
    }
  };
};

export const getWithdrawalTransactionsID = (ID, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: false },
    });
    try {
      const { status, data } = await api.get(`transacao/listar/detalhes/saque?ID=${ID}`);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });
      if (data) callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      if (callback) callback({});
    }
  };
};

export const msBaasTed = (ID_TRANSACAO, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE_TED,
      payload: { ms_baas_loading: true },
    });
    try {
      const { status, data } = await api.post(`ms-baas/ted`, { ID_TRANSACAO });
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { ms_baas_loading: false },
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE_TED,
        payload: { ms_baas_loading: false },
      });
      callback(false);
    }
  };
};

export const getTotalReport = (params = { limit: 10, page: 1 }, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: TRANSACTION_RESPONSE, payload: { relatorio_parametrizado: { loading: true }} });
    api
      .post(`usuario/historico/relatorio_parametrizado`, params)
      .then(({data}) => {
        dispatch({
          type: TRANSACTION_RESPONSE,
          payload: {
            relatorio_parametrizado: {
              pagination: data.HISTORICO.pagination,
              loading: false,
              historico: data.HISTORICO.data
            }
          },
        });
        if (callback) callback(true, data);
      })
      .catch((err) => {
        dispatch({
          type: TRANSACTION_RESPONSE,
          payload: { payload: { relatorio_parametrizado: { loading: false } }, erro: true, erro_message: err },
        });
        if (callback) callback(false, err);
      });
  };
};

//TOTAL
export const getTotalTransactions = (parameter, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.post(`transacao/listar/vendas`, parameter);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false, totalTransactions: data },
      });
      if (data.retorno == 'erro') if (callback) callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      if (callback) callback({});
    }
  };
};

export const getAnalysis = (callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.get(`analise/listar/informacao`);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false, analysis: data },
      });
      if (data.retorno == 'erro') if (callback) callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });
      if (callback) callback({});
    }
  };
};

export const getTotalAcessos = (params = { limit: 10, page: 1 }, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: TRANSACTION_RESPONSE, payload: { loading_log: true } });
    try {
      params.search = removeSpecialCharacters(params.search);
      const { status, data } = await api.post(`log/acesso/listar`, params);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: {
          loading_log: false,
          totalAcesso: data,
          pagination: data.pagination,
        },
      });
      if (data.retorno == 'erro') if (callback) callback(data);
    } catch (error) {
      dispatch({ type: TRANSACTION_RESPONSE, payload: { loading_log: false } });

      if (callback) callback({});
    }
  };
};

export const getPendingPayments = (params = { limit: 10, page: 1 }, callback = () => null) => {
  return async (dispatch) => {
    params.SEARCH = removeSpecialCharacters(params.SEARCH);
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.post(`admin/boleto/buscar_solicitacoes_pagamento`, params);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: {
          loading_transaction: false,
          pagamentos: data.DATA ? data.DATA : data.data.data,
          pagination: data.PAGINATION ? data.PAGINATION : data.data.pagination,
        },
      });

      if (data.retorno == 'erro') if (callback) callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      if (callback) callback({});
    }
  };
};

export const getPayments = (params = { limit: 10, page: 1 }, callback = () => null) => {
  return async (dispatch) => {
    params.SEARCH = removeSpecialCharacters(params.SEARCH);
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.post(`transacao/buscar/pagamentos`, params);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: {
          loading_transaction: false,
          pagamentos: data.DATA ? data.DATA : data.data,
          pagination: data.PAGINATION ? data.PAGINATION : data.pagination,
          totalTrasacao: data.VALOR_TOTAL,
        },
      });

      if (data.retorno == 'erro') if (callback) callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      if (callback) callback({});
    }
  };
};

export const getOnlyOnePayment = (id, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: TRANSACTION_RESPONSE });

    try {
      const { status, data } = await api.get(`transacao/listar/pagamento?id=${id}`);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { pagamento: data.DATA },
      });
      if (data.retorno == 'erro') if (callback) callback(data);
    } catch (error) {
      dispatch({ type: TRANSACTION_RESPONSE });

      if (callback) callback({});
    }
  };
};

export const getBaasBalance = (callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true }
    });

    try {
      const { data } = await api.get(`celcoin/saldo`);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { saldoAtual: data.balance },
      });
      if (data.retorno == 'erro') if (callback) callback(data);
    } catch (error) {
      dispatch({ type: TRANSACTION_RESPONSE });

      if (callback) callback({});
    }
  };
};

// enviar extrato de titulos

export const sendExtractTitles = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      var formData = new FormData();
      for (let i in params) formData.append(i, params[i]);

      const { status, data } = await api.post(`boleto/upload/extrato/titulos`, formData);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });
      if (callback) callback(true);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });
      if (callback) callback(false);
    }
  };
};

export const updateAntecipation = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.post(`conta/resolver/antecipacao`, params);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      callback(data, true);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      const res = error.response ? error.response.data : { menssage: 'Erro ao inserir' };
      callback(res, false);
    }
  };
};

//Teste...

export const updateConciliation = (NUM_PARCELA, ID_TRANSACAO, ID_TRANSACAO_CARTAO, callback = () => null) => {
  return async (dispatch) => {
    var params = { NUM_PARCELA, ID_TRANSACAO, ID_TRANSACAO_CARTAO };

    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.put(`/transacao/cartao/conciliar/parcela`, params);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: {
          loading_transaction: false,
          retornoMensagem: data,
        },
      });

      callback(data, true);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      const res = error.response ? error.response.data : { menssage: 'Erro ao inserir' };
      callback(res, false);
    }
  };
};

export const getSpreadList = (callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.get(`nfe/lista-spread`);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false, spread: data.LIST },
      });
      if (data.retorno == 'erro') if (callback) callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      if (callback) callback({});
    }
  };
};

export const getNfeList = (callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: LISTANF_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.get(`nfe/listarNotaFiscal`);
      dispatch({
        type: LISTANF_RESPONSE,
        payload: {
          loading_transaction: false,
          listaNf: data.data,
          pagination: data.pagination,
        },
      });
    } catch (error) {
      dispatch({
        type: LISTANF_RESPONSE,
        payload: { loading_transaction: false },
      });

      if (callback) callback({});
    }
  };
};

export const downloadNF = (dados, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: DOWNLOADNF_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.post(`nfe/dowload`, dados, {});
      dispatch({
        type: DOWNLOADNF_RESPONSE,
        payload: { loading_transaction: false },
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch({
        type: DOWNLOADNF_RESPONSE,
        payload: { loading_transaction: false },
      });

      if (callback) callback({});
    }
  };
};

export const getTotalTransfer = (
  params = { limit: 10, page: 1, transferenciaInterna: true },
  callback = () => null,
) => {
  return async (dispatch) => {
    dispatch({ type: TRANSACTION_RESPONSE, payload: { loading_log: true } });
    try {
      params.search = removeSpecialCharacters(params.search);
      const { status, data } = await api.post(`transacao/listar/transferencia`, params);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: {
          loading_log: false,
          totalTransfer: data,
          pagination: data.PAGINATION,
        },
      });
      if (data.retorno == 'erro') if (callback) callback(data);
    } catch (error) {
      dispatch({ type: TRANSACTION_RESPONSE, payload: { loading_log: false } });

      if (callback) callback({});
    }
  };
};

export const consultaBoleto = (id, callback = () => null) => {
  return async (dispatch) => {
    if (!id) {
    } else {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });
      try {
        const { status, data } = await api.get(`boleto/consultar?id=${id}`);
        dispatch({
          type: TRANSACTION_RESPONSE,
          payload: { loading_transaction: false },
        });
        if (callback) callback(data);
      } catch (error) {
        dispatch({
          type: TRANSACTION_RESPONSE,
          payload: { loading_transaction: false },
        });
        if (callback) callback({});
      }
    }
  };
};

export const getSaque = (id, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.get(`transacao/saque/ted?id=${id}`);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false, saques: data.data },
      });
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      if (callback) callback({});
    }
  };
};

export const getExtracts = (params = { limit: 10, page: 1 }, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.post(`/transacao/extrato/bs2`, params);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: {
          loading_transaction: false,
          getItens: data.payload.historicoMovimentacao.itens,
        },
      });
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      if (callback) callback({});
    }
  };
};

export const getLoans = (params = { limit: 10, page: 1 }, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.post(`/emprestimo/admin`, params);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: {
          loading_transaction: false,
          emprestimos: data.DATA,
          pagination: data.PAGINATION,
        },
      });
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      return <FixBrokenScreen></FixBrokenScreen>;
    }
  };
};

export const getLoanSchedule = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.get(`/emprestimo/listar/cronograma?CODOPERACAO=${params}`);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false, cronograma: data.parcelas },
      });
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      if (callback) callback({});
    }
  };
};

export const remessa = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    var formData = new FormData();
    for (let i in params) formData.append(i, params[i]);

    //     const { status, data } = await api.post(`boleto/arquivo_conciliacao`, formData)
    //     message = data.message
    //     dispatch({ type: TRANSACTION_RESPONSE, payload: { remessaMessage:data.mensagem, loading_transaction: false } })
    //     if (callback) callback(true)
    // } catch (error) {
    //     dispatch({ type: TRANSACTION_RESPONSE, payload: {loading_transaction: false } })
    //     if (callback) callback(false)
    // }
    api
      .post(`boleto/arquivo_conciliacao`, formData)
      .then((res) => {
        dispatch({
          type: TRANSACTION_RESPONSE,
          payload: {
            successRemessa: true,
            errorRemessa: false,
            remessaMessage: res.mensagem,
            loading_transaction: false,
          },
        });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({
          type: TRANSACTION_RESPONSE,
          payload: {
            successRemessa: false,
            errorRemessa: true,
            remessaMessage: 'Erro ao enviar documento',
            loading_transaction: false,
          },
        });
        if (callback) callback(false, err);
      });
  };
};

export const getRemessas = (params, flagDispatch, callback = () => null) => {
  // return async dispatch => {
  //     dispatch({ type: TRANSACTION_RESPONSE, payload: { loading_transaction: true } })
  //     try {
  //         const { status, data } = await api.post(`boleto/historico_arquivos`, params)
  //         dispatch({ type: TRANSACTION_RESPONSE, payload: { pagination: data.pagination, listRemessa: data.data, loading_transaction: false } })
  //         if (callback) callback(true)
  //     } catch (error) {
  //         dispatch({ type: TRANSACTION_RESPONSE, payload: { loading_transaction: false } })
  //         if (callback) callback(false)
  //     }
  // }
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.post(`boleto/historico_arquivos`, params);

      dispatch({
        type: flagDispatch ? TRANSACTION_RESPONSE : TRANSACTION_REMESSA,
        payload: {
          listRemessa: data.data,
          totalResult: data.pagination.totalResult,
          remessaPagination: data.pagination || {},
        },
      });
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });
    }
  };
};

export const debito = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: LANCAMENTO,
      payload: { loading_transaction: true, retorno: '' },
    });
    try {
      const response = await api.post(`transacao/debito/adm`, params);
      dispatch({
        type: LANCAMENTO,
        payload: { loading_transaction: false, retorno: response.data },
      });
      callback(response.data);
    } catch (error) {
      dispatch({ type: LANCAMENTO, payload: { loading_transaction: false } });
      callback(error.response.data);
    }
  };
};

export const credito = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: LANCAMENTO,
      payload: { loading_transaction: true, retorno: '' },
    });
    try {
      const response = await api.post(`transacao/credito/adm`, params);
      dispatch({
        type: LANCAMENTO,
        payload: { loading_transaction: false, retorno: response.data },
      });
      callback(response.data);
    } catch (error) {
      dispatch({
        type: LANCAMENTO,
        payload: {
          loading_transaction: false,
        },
      });
      callback(error.response.data);
    }
  };
};

export const bloqueio = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: LANCAMENTO,
      payload: { loading_transaction: true, retorno: '' },
    });
    try {
      const response = await api.post(`transacao/bloqueio/adm`, params);
      dispatch({
        type: LANCAMENTO,
        payload: { loading_transaction: false, retorno: response.data },
      });
      callback(response.data);
    } catch (error) {
      dispatch({
        type: LANCAMENTO,
        payload: {
          loading_transaction: false,
        },
      });
      callback(error.response.data);
    }
  };
};

export const cartao_conciliacao = (date, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: LANCAMENTO, payload: { loading_transaction: true } });
    try {
      const { status, data } = await api.post(`cartao/conciliacao`, date);
      dispatch({
        type: LANCAMENTO,
        payload: { loading_transaction: false, mensagem: data.mensagem },
      });
      if (callback) callback(true);
    } catch (error) {
      dispatch({ type: LANCAMENTO, payload: { loading_transaction: false } });
      if (callback) callback(false);
    }
  };
};

export const lancamento = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: LANCAMENTO, payload: { loading_transaction: true } });
    try {
      const { status, data } = await api.post(`transacao/diario`, params);
      dispatch({
        type: LANCAMENTO,
        payload: {
          loading_transaction: false,
          mensagem: data.mensagem,
          sucesso: true,
        },
      });
      if (callback) callback(data);
    } catch (error) {
      if (callback) callback(error);
    }
  };
};

export const getBalanceHistory = (props, callback) => {
  return (dispatch) => {
    dispatch({ type: TRANSACTION_RESPONSE });
    api
      .post(`usuario/historico/saldo`, props)
      .then((res) => {
        dispatch({
          type: TRANSACTION_RESPONSE,
          payload: { loading: false, historico: res.data.HISTORICO },
        });
        if (callback) callback(true, res);
      })
      .catch((err) => {
        dispatch({
          type: TRANSACTION_RESPONSE,
          payload: { loading: false, erro: true, erro_message: err },
        });
        if (callback) callback(false, err);
      });
  };
};

const tableToExcel = (function () {
  const uri = 'data:application/vnd.ms-excel;base64,',
    template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><meta charset="UTF-8"><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
    base64 = function (s) {
      return window.btoa(s);
    },
    format = function (s, c) {
      return s.replace(/{(\w+)}/g, function (m, p) {
        return c[p];
      });
    };
  return function (table, name) {
    if (!table.nodeType) table = document.getElementById(table);
    const ctx = { worksheet: name || 'Worksheet', table: table.innerHTML };
    window.location.href = uri + base64(format(template, ctx));
  };
})();

export const getReportsExcel = ({
  CPFCNPJ_CLIENT = '',
  DATAFINAL = '',
  DATAINICIAL = '',
  valorMax = '',
  valorMin = '',
  tipoTransacao = '',
  modoPagamento = '',
  tipoUsuario = '',
}) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    api
      .get(
        `usuario/exportar/relatorio_parametrizado?CPFCNPJ_CLIENT=${CPFCNPJ_CLIENT}&DATAFINAL=${DATAFINAL}&DATAINICIAL=${DATAINICIAL}&valorMax=${valorMax}&valorMin=${valorMin}&tipoTransacao=${tipoTransacao}&modoPagamento=${modoPagamento}&tipoUsuario=${tipoUsuario}`,
        { responseType: 'blob' },
      )
      .then(function (response) {
        dispatch({
          type: TRANSACTION_RESPONSE,
          payload: { loading_transaction: false },
        });
        let fileName = `relatorio_parametrizado${dayjs().format('DD/MM/YYYY')}.xls`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            }),
            fileName,
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      });
  };
};

export const getBalanceHistoryXLS = (props, callback) => {
  return (dispatch) => {
    dispatch({ type: TRANSACTION_RESPONSE });
    tableToExcel('table_relatorio', 'Example Table');
  };
};

export const getTransactionsType = (callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.get(`/transacao/tipo_transacao`);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: {
          loading_transaction: false,
          modoPagamento: data.modo_pagamento,
          tiposTransacoes: data.tipo_transacao,
          statusTransacao: data.status_transacao,
          parceiro: data.parceiro,
        },
      });
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      if (callback) callback({});
    }
  };
};
export const getStatusEmprestimo = (callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { status, data } = await api.get(`emprestimo/listar_status`);
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: {
          filtro_emprestimo: data.status_emprestimo,
        },
      });
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      if (callback) callback({});
    }
  };
};

export const getTransactionsAvulse = (params = { limit: 10 }, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });

    try {
      const { status, data } = await api.post(`transacao/avulsa`, params);

      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: {
          pagination: data.retorno.pagination,
          loading_transaction: false,
          retorno: data.retorno.data,
        },
      });
      if (callback) callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });

      if (callback) callback({});
    }
  };
};

export const getStatusTransacao = (callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { data } = await api.get(`servicos_digitais/listar/status_transacao`);

      let allStatus = ['TODOS'];
      let idCodes = [''];

      data.data.map((status, index) => {
        allStatus[index + 1] = status.DESCRICAO;
      });

      data.data.map((status, index) => {
        idCodes[index + 1] = status.ID;
      });

      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: {
          loading_transaction: false,
          status_transaction: allStatus,
          idCodes: idCodes,
        },
      });
      return data.data;
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });
    }
  };
};

export const getDigitalService = (
  params = {
    limit: 10,
    page: 1,
    search: '',
    dataFinal: '',
    dataInicial: '',
    status: '',
  },
  callback = () => null,
) => {
  const { limit, search, page, dataInicial, dataFinal, status, servicos_digitais, tipo_pagamento } = params;

  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE,
      payload: { loading_transaction: true },
    });
    try {
      const { data } = await api.get(
        `servicos_digitais/listar/transacoes?DATA_INICIO=${dataInicial}&DATA_FIM=${dataFinal}&PAGE=${page}&LIMIT=${limit}&STATUS=${status}&SERVICOS_DIGITAIS=${servicos_digitais}&TIPO_PAGAMENTO=${tipo_pagamento}&SEARCH=${search}`,
      );

      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: {
          loading_transaction: false,
          digitalServices: data,
          pagination: data.pagination,
        },
      });
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
        payload: { loading_transaction: false },
      });
    }
  };
};

export const getTedHistory = (ID) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE_TED,
      payload: { loading_transaction_ted: true },
    });
    try {
      if (ID != undefined) {
        const { data } = await api.post(`transacao/listar-logstatus-ted`, {
          ID: ID,
        });
        dispatch({
          type: TRANSACTION_RESPONSE_TED,
          payload: {
            loading_transaction_ted: false,
            historyTed: data,
          },
        });
      } else {
        dispatch({
          type: TRANSACTION_RESPONSE_TED,
          payload: {
            loading_transaction_ted: false,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE_TED,
        payload: { loading_transaction_ted: false, historyTed: [] },
      });
    }
  };
};

export const tedAproved = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE_TED,
      payload: { loading_transaction_ted: true },
    });
    try {
      const response = await api.post(`transacao/confirmar-ted`, params);
      dispatch({
        type: TRANSACTION_RESPONSE_TED,
        payload: {
          loading_transaction_ted: false,
          tedAprovedRes: response,
        },
      });
      callback(response);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE_TED,
        payload: {
          loading_transaction_ted: false,
          tedAprovedRes: error.response,
        },
      });
      callback(error.response);
    }
  };
};

export const tedNegaded = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE_TED,
      payload: { loading_transaction_ted: true },
    });

    try {
      const response = await api.post(`transacao/cancelar-ted`, params);
      dispatch({
        type: TRANSACTION_RESPONSE_TED,
        payload: {
          loading_transaction_ted: false,
          tedAproved: response,
        },
      });
      callback(response);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE_TED,
        payload: {
          loading_transaction_ted: false,
          tedAproved: error.response,
        },
      });
      callback(error.response);
    }
  };
};

export const tedRejected = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE_TED,
      payload: { loading_transaction_ted: true },
    });

    try {
      const response = await api.post(`/transacao/reenvio-ted-cem`, params);
      dispatch({
        type: TRANSACTION_RESPONSE_TED,
        payload: {
          loading_transaction_ted: false,
          tedRejected: response,
        },
      });
      callback(response);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE_TED,
        payload: {
          loading_transaction_ted: false,
          tedRejected: error.response,
        },
      });
      callback(error.response);
    }
  };
};

export const getLimits = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE
    });
    const query = Object.entries(params).reduce((acc, [key, value]) => {
      value && value !== '' && value !== 'Todos' && acc.push(`${key}=${value}`);
      return acc;
    }, []).join('&');
    try {
      const { data } = await api.get(`/limites-pix/buscar?${query}`);
      dispatch({
        type: TRANSACTION_RESPONSE,
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
      });
      callback(error.response);
    }
  };
};

export const getRequestLimits = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: TRANSACTION_RESPONSE
    });
    const query = Object.entries(params).reduce((acc, [key, value]) => {
      value && value !== '' && value !== 'Todos' && acc.push(`${key}=${value}`);
      return acc;
    }, []).join('&');
    try {
      const { data } = await api.get(`/limites-pix/buscar-pendentes?${query}`);
      dispatch({
        type: TRANSACTION_RESPONSE,
      });
      callback(data);
    } catch (error) {
      dispatch({
        type: TRANSACTION_RESPONSE,
      });
      callback(error.response);
    }
  };
};

export const approveSolicitationLimit = (data, callback = () => null) => {
  return async (dispatch) => {
    dispatch({ type: TRANSACTION_RESPONSE});
    
    await api.post('/limites-pix/analizar', data).then(({data}) => callback(data)).catch(({response}) => callback(response));
    dispatch({ type: TRANSACTION_RESPONSE});
  }
}


export const saldoAporteCartao = (callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: APORTE_RESPONSE,
      payload: { loading_aporte: true },
    });
    try {
      const { status, data } = await api.get(`/cartao/aporte`);
      dispatch({
        type: APORTE_RESPONSE,
        payload: {
          loading_aporte: false,
          cartao: {
            aporte: data.valor,
            pre_pago: data.data['valor-pre-pago'],
            pos_pago: data.data['valor-pos-pago'],
            limite_aporte: data.limiteAporte,
            danger: data.danger,
          },
        },
      });

      if (data.danger) {
        enqueueSnackbar('O saldo disponível da SWAP está abaixo do limite predefinido.', {
          persist: true,
          variant: 'warning',
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      }
      if (callback) callback(data);
    } catch (error) {
      dispatch({
        type: APORTE_RESPONSE,
        payload: { loading_aporte: false },
      });
      if (callback) callback({});
    }
  };
};

export const saldoAportePix = (callback = () => null) => {
  return async (dispatch) => {
    dispatch({
      type: APORTE_RESPONSE,
      payload: { loading_aporte: true },
    });
    try {
      const {
        status,
        data,
        data: { saldo },
      } = await api.get(`admin/conta-pi/saldo`);
      dispatch({
        type: APORTE_RESPONSE,
        payload: {
          loading_aporte: false,
          pix: {
            saldo: saldo.sldDisponivelSPI,
            limite_aporte: saldo.limite_aporte,
            danger: data.saldo.danger,
          },
        },
      });
      if (data.saldo.danger) {
        enqueueSnackbar('O saldo disponível da JD está abaixo do limite predefinido.', {
          persist: true,
          variant: 'warning',
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      }
      if (callback) callback(data);
    } catch (error) {
      dispatch({
        type: APORTE_RESPONSE,
        payload: { loading_aporte: false },
      });
      if (callback) callback({});
    }
  };
};
