import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button, ModalFooter } from 'reactstrap';
import { formatMoney } from '../../../utils/formatMoneyBRL';
import formatCPF from '../../../utils/formatCPF';
import formatCNPJ from '../../../utils/formatCNPJ';
import valueEmpty from '../../../utils/valueEmpty';
import isCpf from '../../../utils/isCpf';
import StatusContainer from '../../StatusContainer/StatusContainer.js';
import ModalMensagemSemReload from '../../../views/Transaction/ModalMensagemSemReload.jsx';
import api from '../../../service/api.js';
import Loading from '../../Loader/Loading.jsx';
import ModalSenha from '../Confirmar/ModalSenha.jsx';
import { Grid } from '@material-ui/core';
import { formatDateBR } from '../../../utils/dateKyc.js';

export default function ModalPagamentoPendente({ modal, toggle, title, idSolicitation, callback }) {
  const [modalMensagem, setModalMensagem] = useState({ open: false, message: '', success: false });
  const [boletoSolicitation, setBoletoSolicitation] = useState([]);
  const [modalPassword, setModalPassword] = useState({ open: false, callback: () => { } });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (idSolicitation) {
      setLoading(true);
      api.get(`/admin/boleto/buscar_solicitacoes_pagamento/${idSolicitation}`).then(({ data }) => {
        setBoletoSolicitation(data.data);
        console.log(data.data);
        setLoading(false);
      });
    }
  }, [modal]);

  const reproveSolicitationBoleto = () => {
    setLoading(true);
    api
      .post(`admin/boleto/reprovar_solicitacao_pagamento/${idSolicitation}`)
      .then(({ data }) => {
        setLoading(false);
        if (data.retorno == 'sucesso') {
          setModalMensagem({ open: true, message: data.mensagem, success: true });
        } else {
          setModalMensagem({ open: true, message: data.mensagem, success: false });
        }
        toggle();
        callback();
      })
      .catch(({ response }) => {
        setLoading(false);
        setModalMensagem({ open: true, message: response.data.mensagem, success: false });
        toggle();
        callback();
      });
  };

  const approveSolicitationBoleto = (senha) => {
    setLoading(true);
    api
      .post(`/admin/boleto/aprovar_solicitacao_pagamento/${idSolicitation}`)
      .then(({ data }) => {
        setLoading(false);
        if (data.retorno == 'sucesso') {
          setModalMensagem({ open: true, message: data.mensagem, success: true });
        } else {
          setModalMensagem({ open: true, message: data.mensagem, success: false });
        }
        toggle();
        callback();
      })
      .catch(({ response }) => {
        setLoading(false);
        setModalMensagem({ open: true, message: response.data.mensagem, success: false });
        toggle();
        callback();
      });
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        {loading ? (
          <Loading color="blue" />
        ) : (
          <>
            <ModalHeader toggle={toggle}>
              <h3>{title}</h3>
            </ModalHeader>
            <ModalBody>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <h5>Nome do Pagador</h5>
                  <p>{boletoSolicitation.NOME_PAGADOR}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h5>Conta do Pagador</h5>
                  <p>{boletoSolicitation.CONTA_PAGADOR}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h5>Status Transação</h5>
                  <StatusContainer
                    code={boletoSolicitation.STATUS_TRANSACAO}
                    description={boletoSolicitation.STATUS_TRANSACAO_DESCRICAO}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <h5>Data / Hora Solicitação:</h5>
                  <p>{formatDateBR(boletoSolicitation.CREATED_AT_REAL)}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h5>Data / Hora Vencimento:</h5>
                  <p>{boletoSolicitation.DATA_VENCIMENTO !== "0000-00-00 00:00:00" ? formatDateBR(boletoSolicitation.DATA_VENCIMENTO) : valueEmpty()}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h5>CPF/CNPJ</h5>
                  <p>
                    {boletoSolicitation.CPFCNPJ_PAGADOR && isCpf(boletoSolicitation.CPFCNPJ_PAGADOR)
                      ? formatCPF(valueEmpty(boletoSolicitation.CPFCNPJ_PAGADOR))
                      : formatCNPJ(valueEmpty(boletoSolicitation.CPFCNPJ_PAGADOR))}
                  </p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h5>Valor do Boleto</h5>
                  <p>{(+boletoSolicitation.VALOR).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h5>Taxa</h5>
                  <p>{(+boletoSolicitation.SPREAD).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h5>Juros</h5>
                  <p>{(+boletoSolicitation.JUROS).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h5>Multa</h5>
                  <p>{(+boletoSolicitation.MULTA).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h5>Descontos</h5>
                  <p>{(+boletoSolicitation.TOTAL_DESCONTOS).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h5>Tipo do Pagamento</h5>
                  <p>{boletoSolicitation.MODO_PAGAMENTO_DESCRICAO}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h5>Valor Pago</h5>
                  <p>{(+boletoSolicitation.VALOR_TOTAL).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h5>Nome do Recebedor</h5>
                  <p>{boletoSolicitation.NOME_RECEBEDOR}</p>
                </Grid>
                <Grid item xs={12}>
                  <h5>Linha Digitável</h5>
                  <p>{boletoSolicitation.LINHA_DIGITAVEL}</p>
                </Grid>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                size="sm"
                block
                onClick={() => setModalPassword({ open: true, callback: reproveSolicitationBoleto })}
              >
                Reprovar
              </Button>
              <Button
                color="success"
                size="sm"
                block
                onClick={approveSolicitationBoleto}
              >
                Aprovar
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
      <ModalMensagemSemReload
        modal={modalMensagem.open}
        toggle={() => setModalMensagem({ open: false, message: '', success: false })}
        mensagem={modalMensagem.message}
        sucesso={modalMensagem.success}
      />
      <ModalSenha
        modal={modalPassword.open}
        close={() => setModalPassword({ open: false, callback: () => { } })}
        nextFunction={modalPassword.callback}
      />
    </>
  );
}

const styles = {
  metade: { width: '49%' },
  cheio: { width: '100%' },
};
